import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import InfoIcon from '../../shared/icon/InfoIcon';
import { HeadingSize } from '../../shared/text/Heading';
import Tooltip from '../../shared/Tooltip';
import { Option } from '../../Option';
import { defaultTitleStyle, useTextStyle } from './ActionEditorBase';
import { useFormRendererInfo } from '../../../contexts/FormRendererContext';
import { useWarnOutboundLink } from '../../../hooks/useWarnOutboundLink';
import ActionIndicators from './ActionIndicators';
import { useFormAction } from '../../../contexts/FormActionContext';
import { useTranslation } from 'react-i18next';
import InlineEditor from '../../shared/form-control/InlineEditor';
import { EventSystem } from '../../../events/EventSystem';
import { MenuAction } from '../../shared/placeholder/PlaceholderSelectMenu';
import ReactLinkify from 'react-linkify';
import { SecureLink } from 'react-secure-link';
import { interpolateActionData } from '../../../utils/interpolation/ActionDataInterpolator';
import HtmlPreview from '../../shared/placeholder/HtmlPreview';
import { convertToRichText, plainText } from '../../../utils/RichTextUtils';

type ActionTitleDescriptionProps = {
  question?: string;
  description?: string;
  requiresDescription?: boolean;
  help?: string;
  requiresHelp?: boolean;
  required?: boolean;
  titleStyle?: Option<HeadingSize, string>;
};

const ActionTitleDescription: FC<ActionTitleDescriptionProps> = (props) => {
  const { question, description, required, help, requiresDescription, requiresHelp, titleStyle = defaultTitleStyle } = props;
  const selectedTitleStyle = useTextStyle(titleStyle.id);
  const { placeholders, featureToggles, config, adHocAnswers, onAdHocAnswer } = useFormRendererInfo();
  const externalLinkWarner = useWarnOutboundLink();
  const { currentAction, currentSection, createPlaceholder, deletePlaceholder, busySavingAction } = useFormAction();
  const { t } = useTranslation(['common', 'activity-type']);
  const [dynamicDataMenuOpen, setDynamicDataMenuOpen] = useState(false);
  const [placeholdersAdded, setPlaceholdersAdded] = useState(false);
  const [value, setValue] = useState(question);

  const onTitleChange = useCallback(
    (newValue: string) => {
      if (!currentAction.fieldId) return;
      setValue(newValue);
      const currentAnswer = adHocAnswers[currentAction.id]?.find((answer) => answer.fieldId === currentAction.fieldId)?.question;
      if (placeholdersAdded && !busySavingAction) {
        onAdHocAnswer(currentAction.id, currentAction.fieldId, { questionData: { ...(currentAnswer ?? {}), title: newValue } });
        setPlaceholdersAdded(false);
      }
    },
    [adHocAnswers, busySavingAction, currentAction.fieldId, currentAction.id, onAdHocAnswer, placeholdersAdded],
  );

  const onBlur = useCallback(() => {
    if (!currentAction.fieldId) return;
    if (!!value && value === (question || '')) return;
    if (dynamicDataMenuOpen || busySavingAction) return;
    const currentAnswer = adHocAnswers[currentAction.id]?.find((answer) => answer.fieldId === currentAction.fieldId)?.question;
    onAdHocAnswer(currentAction.id, currentAction.fieldId, { questionData: { ...(currentAnswer ?? {}), title: value } });
  }, [adHocAnswers, busySavingAction, currentAction.fieldId, currentAction.id, dynamicDataMenuOpen, onAdHocAnswer, question, value]);

  useEffect(() => {
    const openedHandler = ({ action }: { action: MenuAction }) => {
      if (currentAction.id !== action.targetId || currentAction.fieldId !== action.fieldId) return;
      setDynamicDataMenuOpen(true);
    };
    const closedHandler = ({ action }: { action: MenuAction }) => {
      if (currentAction.id !== action.targetId || currentAction.fieldId !== action.fieldId) return;
      setDynamicDataMenuOpen(false);
    };

    EventSystem.listen('dynamic-data-popup-opened', openedHandler);
    EventSystem.listen('dynamic-data-popup-closed', closedHandler);

    return () => {
      EventSystem.stopListening('dynamic-data-popup-opened', openedHandler);
      EventSystem.stopListening('dynamic-data-popup-closed', closedHandler);
    };
  }, [currentAction.fieldId, currentAction.id]);

  return (
    <>
      {externalLinkWarner.modal}
      <div data-cy="question">
        <div className="mb-2 flex w-full items-start justify-between gap-4">
          <div className="flex w-full items-start">
            <InlineEditor
              size={HeadingSize.CUSTOM}
              className={`${selectedTitleStyle.value} ${currentAction.fieldId && !question ? 'text-gray-3 font-normal' : ''}`}
              id={`question-title-${currentAction.id}`}
              usePlaceholderTextbox
              enableDynamicData={!currentSection.isPublic}
              disabled={!featureToggles?.enableInlineTitleEdit}
              value={value || ''}
              form={config}
              action={currentAction}
              placeholder={currentAction.fieldId ? t('activity-type:ad-hoc-fields.field-question') : undefined}
              onChange={onTitleChange}
              onPlaceholderRemoved={deletePlaceholder}
              onPlaceholdersChange={(newPlaceholders) => {
                for (const placeholder of newPlaceholders) {
                  if (placeholders[placeholder.placeholder]) continue;
                  createPlaceholder(placeholder);
                }
                setPlaceholdersAdded(true);
              }}
              canCloseEditor={!dynamicDataMenuOpen && !busySavingAction}
              onBlur={onBlur}
            >
              <InlineEditor.Slot name="view">
                <div>
                  <ReactLinkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <SecureLink href={decoratedHref} key={key} className="text-link-1 font-bold underline" onClick={externalLinkWarner.onClick}>
                        {decoratedText}
                      </SecureLink>
                    )}
                  >
                    {currentAction.number}{' '}
                    {interpolateActionData(
                      plainText(question || (currentAction.fieldId ? t('activity-type:ad-hoc-fields.field-question') : '')),
                      placeholders,
                    )}
                  </ReactLinkify>
                  {required ? <span aria-label={t('aria-label.required')}>*</span> : ''}
                </div>
                {requiresHelp && !!help && (
                  <Tooltip
                    text={
                      requiresHelp &&
                      !!help && (
                        <div className="max-w-lg">
                          <HtmlPreview placeholders={placeholders}>{convertToRichText(help || '')?.value || ''}</HtmlPreview>
                        </div>
                      )
                    }
                  >
                    {(tooltip) => (
                      <div className="ml-1" {...tooltip}>
                        <InfoIcon className="h-6 w-6" />
                      </div>
                    )}
                  </Tooltip>
                )}
              </InlineEditor.Slot>
            </InlineEditor>
          </div>
          <div className="flex items-center gap-1">
            <ActionIndicators />
          </div>
        </div>
      </div>
      {(requiresDescription || requiresDescription === undefined || requiresDescription === null) && (
        <span data-cy="description" className="text-color-3 empty:hidden" aria-hidden={!description} id={`question-description-${currentAction.id}`}>
          <HtmlPreview placeholders={placeholders} warnOutboundLinks>
            {convertToRichText(description || '')?.value || ''}
          </HtmlPreview>
        </span>
      )}
    </>
  );
};

export default ActionTitleDescription;
